import React from 'react';
import Layout, {
    bgImageDefault,
    PageHeaderC,
    PageSection,
} from '../components/layout';
import { graphql } from 'gatsby';
import { PageSubtitle, PageText } from '../components/typography';
import { Trans } from 'gatsby-plugin-react-i18next';
import { ContactSection } from '../components/contact';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/seo';

function About({ data, location }) {
    return (
        <Layout
            location={location}
            headerContent={
                <PageHeaderC title={<Trans i18nKey="title">Über Uns</Trans>} />
            }
            bgImage={bgImageDefault.default}
        >
            <Seo title="Über Uns" />
            <PageSection isDark>
                <PageSubtitle hasMargin={false}>
                    <PageText inline type="highlighted">
                        condignum{' '}
                    </PageText>
                    <PageText inline type="dimmed">
                        <Trans i18nKey="subtitle1">
                            (aus dem Lateinischen für “angemessen”)
                        </Trans>
                    </PageText>{' '}
                    <Trans i18nKey="subtitle2">
                        ist&nbsp;ein&nbsp;österreichisches
                        Cyber-Security-Unternehmen mit dem Ansporn, die digitale
                        Welt ein großes Stück sicherer zu&nbsp;machen.
                    </Trans>
                </PageSubtitle>
            </PageSection>
            <PageSection size="medium">
                <PageText>
                    <Trans i18nKey="p1">
                        Durch die rasante technologische Weiterentwicklung und
                        mit zunehmenden&nbsp;Regulatorien wird das Thema
                        Informationssicherheit nicht nur komplexer, sondern es
                        wird auch immer schwieriger mit diesen Entwicklungen
                        Schritt&nbsp;zu&nbsp;halten.
                    </Trans>
                </PageText>
                <PageText>
                    <Trans i18nKey="p2">
                        Wir digitalisieren und automatisieren Cyber-Sicherheit
                        und liefern Organisationen jeder Größe die notwendige
                        Hilfestellung um aus eigener Kraft sicherer zu werden.
                        Im Fokus steht unsere digitale Security Management
                        Plattform (SaaS) welche von darauf aufbauenden Security
                        Services und Consulting ergänzt wird.
                    </Trans>
                </PageText>

                <div className="text-center mt-12 w-80 mx-auto">
                    <StaticImage
                        src="../images/homepage_header.png"
                        width={400}
                        objectFit="contain"
                        placeholder="none"
                        quality={100}
                        formats={['auto', 'png']}
                        alt="appropriate steps to ensure Information security"
                    />
                    <PageText type="dimmed" className="mt-3">
                        "appropriate steps to ensure Information security"
                    </PageText>
                </div>
            </PageSection>
            <ContactSection isDark={false} />
        </Layout>
    );
}

export default About;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["about"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
